import { Except } from 'type-fest';
import {
  ApplicationEntity,
  ApplicationQuote,
  LeaseEntity,
  QuestionnaireReplyAnswer,
  RecordCheckFields,
} from '../../api/api-types';
import { StatusInfoTypes } from '../../components/status-info/StatusInfo.types';
import { Application, QuoteFeesAndTaxes } from '@theguarantors/tg-modern-business';
import { WorkflowAuditEntity } from './components/DecisionSummaryInfo/decision-summary-info.types';

export interface CurrentAddress {
  lineOne: string;
  lineTwo?: string;
  unit: string;
  city: string;
  country: string;
  region: string;
  postal: string;
}

export interface QuestionnaireInfoProps {
  answers: QuestionnaireReplyAnswer[];
  reportData?: Partial<RecordCheckFields>;
  equifaxResponse?: Record<string, unknown>;
  showDecisionSummaryInfoEnabled?: boolean;
  children?: never;
  application: ApplicationEntity | undefined;
  changes: Partial<DetailsChanges>;
  setChanges: (name: DetailsChangesKey, changes: DetailsChanges[DetailsChangesKey.APPLICATION] | undefined) => void;
}

export interface PolicyResponseLeaseProperty {
  displayName: string;
  mainAddress: string;
  city: string;
  state: string;
  zipCode: string;
  llc: string;
}

export interface PolicyResponse {
  id: number;
  bondNumber: string;
  status: string;
  createdAt: string;
  deal: {
    carrier: string;
  };
  lease: {
    addressUnit: string;
    leaseStartDate: string;
    leaseEndDate: string;
    property: PolicyResponseLeaseProperty;
    organization: {
      name: string;
    };
    rentCoverage: number;
    depositsCoverage: number;
    freeRent: number;
    prepaidRent: number;
  };
  documents: PolicyDocument[];
  premium: {
    amount: number;
    context: {
      monthlyRent: number;
      coverageMonths: number;
      amountSDR: number;
      premiumRate: number;
      penalSum: number;
      premiumAmountWithoutUpfrontDiscount: number;
      premiumAmountBeforeTax?: number;
    };
  };
  previousPolicyId: number;
}

export interface PolicyDocument {
  id: string;
  fileId: string;
  type: string;
}

export interface CurrentPolicyInfoProps {
  dealId: number;
  policyData: PolicyResponse;
  policyHistory: PolicyResponse[];
  riskCategory: string;
  children?: never;
  coverageInputDifferences: CoverageInputDifferences | null;
  applicationId: number;
  isVouchedPremium: boolean;
  isMonthlyPayment: boolean;
  amountPaid?: number | null;
  quoteData: QuoteData | null | undefined;
  refetch: () => void;
}
export interface LeaseInfoProps {
  application: ApplicationEntity | undefined;
  changes: Partial<DetailsChanges>;
  setChanges: (name: DetailsChangesKey, changes: DetailsChanges[DetailsChangesKey.LEASE] | undefined) => void;
  locked?: boolean;
  lockedCoverageFields?: boolean;
  children?: never;
  coverageInputDifferences: CoverageInputDifferences | null;
  refetch: () => void;
}

export interface ApplicationDetailsResponse {
  application: ApplicationDetailsResponseApplication;
}

export interface ApplicationDetailsResponseApplication extends ApplicationEntity {
  policies: PolicyResponse[];
  workflowAudits: WorkflowAuditEntity[];
}

export interface FileLinkComponentProps {
  title: string;
  documentId?: string;
  fileId?: string;
  fileName?: string;
  dataTestId?: string;
}

export type LeaseUpdates = Partial<Except<LeaseEntity, 'id' | 'property'>>;
export type ApplicationUpdates = Partial<
  Pick<
    Application,
    | 'lgCoverageOverride'
    | 'sdrCoverageOverride'
    | 'pmsiCurrentResidentMatchId'
    | 'pmsiProspectMatchId'
    | 'screeningStatus'
  >
>;

export interface Change<T> {
  value: T | undefined;
  error?: string;
}

export enum DetailsChangesKey {
  LEASE = 'lease',
  APPLICATION_SETTINGS = 'applicationSettings',
  APPLICATION = 'application',
}
export interface ObjectWithStringKeys {
  [key: string]: any;
}

export interface LeaseChanges extends ObjectWithStringKeys {
  leaseStartDate?: Change<Date | null>;
  leaseEndDate?: Change<Date | null>;
  addressUnit?: Change<string>;
  monthlyRent?: Change<string>;
  rentCoverage?: Change<string>;
  depositsCoverage?: Change<string>;
  freeRent?: Change<string>;
  prepaidRent?: Change<string>;
}

export interface ApplicationSettingsChanges extends ObjectWithStringKeys {
  comment?: Change<string>;
}

export interface ApplicationChanges extends ObjectWithStringKeys {
  lgCoverageOverride?: Change<string>;
  sdrCoverageOverride?: Change<string>;
  screeningStatus?: Change<string>;
  pmsiCurrentResidentMatchId?: Change<string>;
  pmsiProspectMatchId?: Change<string>;
}

export type DetailsChanges = {
  [DetailsChangesKey.LEASE]: LeaseChanges;
  [DetailsChangesKey.APPLICATION_SETTINGS]: ApplicationSettingsChanges;
  [DetailsChangesKey.APPLICATION]: ApplicationChanges;
};

export type CoverageInputDifferences = Record<
  keyof DetailsChanges[DetailsChangesKey.LEASE] & keyof DetailsChanges[DetailsChangesKey.APPLICATION],
  boolean
>;

export interface InfoState {
  id: number;
  message: string;
  type: StatusInfoTypes;
}

export interface QuoteData
  extends Pick<
      ApplicationQuote,
      | 'rateFilingData'
      | 'premiumRate'
      | 'monthlyRent'
      | 'penalSum'
      | 'carrier'
      | 'coverageMonths'
      | 'amountSDR'
      | 'riskCategory'
      | 'premiumAmount'
      | 'premiumAmountWithoutUpfrontDiscount'
      | 'lgPremiumAmount'
      | 'sdrPremiumAmount'
      | 'esEnabled'
    >,
    QuoteFeesAndTaxes {
  rentCoverage: LeaseEntity['rentCoverage'];
  depositsCoverage: LeaseEntity['depositsCoverage'];
  lgPremiumAmountWithoutUpfrontDiscount?: number;
  sdrPremiumAmountWithoutUpfrontDiscount?: number;
}

export interface MonthlyPayment {
  isMonthlyPayment: boolean;
}

export interface QuoteInfoProps {
  application: ApplicationEntity;
  children?: never;
}

export interface CommentsSectionProps {
  changes: DetailsChanges[DetailsChangesKey.APPLICATION_SETTINGS] | undefined;
  setChanges: (
    name: DetailsChangesKey.APPLICATION_SETTINGS,
    changes: DetailsChanges[DetailsChangesKey.APPLICATION_SETTINGS] | undefined,
  ) => void;
  commentData: string | null;
}

export interface PolicyInfoModalProps {
  id: number;
  applicationId: number;
  title: string;
  description: string;
  actionType: UpdatePolicyActionTypes;
  isOpen: boolean;
  refetch: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

export interface CancelPolicyModalProps {
  id: number;
  applicationId: number;
  actionType: UpdatePolicyActionTypes;
  isOpen: boolean;
  premiumCancellationFee: string;
  premiumAmount: number;
  premiumAmountBeforeTax?: number | null;
  isVouchedPremium: boolean;
  isMonthlyPayment: boolean;
  amountPaid?: number | null;
  refetch: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

export interface SelectRefundOption {
  option: string;
  value: string;
}

export enum UnavailableCancelPolicyStatuses {
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
}

export enum UpdatePolicyActionTypes {
  AMEND = 'amend',
  CANCEL = 'cancel',
}

export interface RegulatorFormsListResponse {
  regulatorFormsList: string[];
}

export enum RegulatorFormDocumentTypes {
  'Bond Declaration' = 'BOND_DECLARATION_PAGE',
  'SL-1' = 'SL1',
  'SL-2' = 'SL2',
  'Form 1609' = 'FORM_1609',
  'Diligent Search Form' = 'DILIGENT_SEARCH_FORM',
}
