import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const GET_FRAUD_INQUIRY = gql`
  query GetFraudInquiry($applicationId: Int!) {
    fraudInquiry(applicationId: $applicationId) {
      score
      status
      externalProviderId
      processedAt
    }
  }
`;

export const useGetFraudInquiry = (applicationId: number | undefined, featureFlagEnabled = false) => {
  const { data, ...rest } = useQuery(GET_FRAUD_INQUIRY, {
    variables: { applicationId },
    skip: !applicationId && !featureFlagEnabled,
  });

  return {
    data: data?.fraudInquiry,
    ...rest,
  };
};
