import gql from 'graphql-tag';

export const FraudInquiryTypeDef = gql`
  extend type Query {
    fraudInquiry(applicationId: Int!): FraudInquiryEntity
  }

  type FraudInquiryEntity {
    score: Float!
    status: String!
    externalProviderId: String!
    processedAt: String!
  }
`;

export default [FraudInquiryTypeDef];
