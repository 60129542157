import gql from 'graphql-tag';
import PropertyTypeDef from './Property';
import DealTypeDef from './Deal';
import ContactTypeDef from './Contact';
import InvitationTypeDef from './Invitation';
import ApplicationPaymentTypeDef from './ApplicationPayment';
import CancelDealTypeDef from './CancelDeal';
import EvaluateApplicationTypeDef from './EvaluateApplication';
import ProcessEvaluatedApplicationTypeDef from './ProcessEvaluatedApplication';

const ApplicationsTypeDef = gql`
  extend type Query {
    summaryApplications: [SummaryApplicationEntity]
    paginatedSummaryApplications(pagination: PaginatedApplicationsInput): PaginatedSummaryApplications
    applications: [ApplicationEntity]
    application(applicationId: Int!): ApplicationEntity
  }

  type SummaryApplicationEntity {
    id: Int!
    status: String!
    firstName: String
    lastName: String
    monthlyRent: Float
    leaseStartDate: String
    addressUnit: String
    displayName: String
    applicantEmail: String
  }

  type PaginatedSummaryApplications {
    data: [DealsListItemEntity]
    page: Int!
    count: Int!
    total: Int!
    pageCount: Int!
  }

  type DealsListItemEntity {
    id: Int!
    status: String!
    applicant: String
    email: String
    monthlyRent: Float
    leaseStartDate: String
    buildingAndUnit: String
  }

  input PaginatedApplicationsInput {
    pageIndex: Int
    pageSize: Int
    sortBy: [SortBy]
    globalFilter: String
  }

  input SortBy {
    id: String
    desc: Boolean
  }

  union ApplicationQuoteOptions = ApplicationQuote | Quote

  type ApplicationEntity {
    id: Int!
    status: String!
    firstName: String
    lastName: String
    phoneNumber: String
    deal: DealEntity
    applicantEmail: String!
    contactId: Int!
    contact: ContactEntity
    reply: ReplyEntity
    invitation: InvitationEntity
    reports: [ReportEntity]
    workflowAudits: [WorkflowAuditEntity]
    policies: [PolicyEntity]
    normalizedFields: NormalizedFields
    payment: ApplicationPaymentEntity
    quote: ApplicationQuoteOptions
    settings: ApplicationSettings
    externalApplicationId: Int
    leaseSnapshot: ApplicationLeaseSnapshot
    lgCoverageOverride: Float
    sdrCoverageOverride: Float
    screeningStatus: String
    renterProvidedData: ApplicationRenterProvidedData
    quoteId: Int
    pmsiProspectMatchId: String
    pmsiCurrentResidentMatchId: String
    calculatedDefaultCoverages: DefaultCalculatedCoverages
  }

  type NormalizedFields {
    dob: String
    ssn: String
    residency: String
    recordCheck: RecordCheckFields
    riskCategory: String
    firstTimeRenter: Boolean
    employmentStatus: String
    creditCheckAuthorization: Boolean
    autoUWVersion: Int
  }

  type RecordCheckFields {
    ficoScore: Int
    isSsnMatched: Boolean
    ficoReasonsCount: Int
    isLastNameMatched: Boolean
    unpaidCollections: Int
    isFirstNameMatched: Boolean
    bankruptcyDateFiled: String
    isDateOfBirthMatched: Boolean
    bankruptcyCurrentDispositionDate: String
    addressMatchStatus: String
    isAddressMatched: Boolean
  }

  type MonthlyPaymentTaxes {
    stampingFee: Float
    surplusLinesTax: Float
    premiumAmountBeforeTax: Float
  }

  type ApplicationQuote {
    # for payment section
    premiumAmount: Float
    premiumAmountWithoutUpfrontDiscount: Float
    lgPremiumAmountWithoutUpfrontDiscount: Float
    sdrPremiumAmountWithoutUpfrontDiscount: Float

    # for quote section
    penalSum: Float
    amountSDR: Float
    monthlyRent: Float
    premiumRate: Float
    coverageMonths: Float
    carrier: String
    riskCategory: String
    rateFilingData: String
    lgPremiumAmount: Float
    sdrPremiumAmount: Float

    # rest of the fields
    rateFilingVersion: String
    lockedCalculationDate: String
    premium: Float
    termFactor: Float
    amountSaved: Float
    leaseMonths: Float
    isRenewalBond: Boolean
    addRiskPremium: Float
    fullRentAmount: Float
    electedCollateral: Float
    discountCollateral: Float
    totalCoverageAmount: Float
    collateralDiscountFactor: Float
    stampingFee: Float
    surplusLinesTax: Float
    monthlyPaymentTaxes: MonthlyPaymentTaxes
    esEnabled: Boolean
  }

  type QuoteMeta {
    quoteDate: String
    isEligibleForMonthlyPayment: Boolean
    lockedCalculationDate: String
  }

  type TaxesAndRegulatoryFees {
    excessAndSurplusLinesTax: Float
    stampingFee: Float
  }

  type TransactionFees {
    cardFee: Float
  }

  type QuotePaymentDetails {
    rentCoveragePremiumBase: Float
    rentCoveragePenalSum: Float
    rentCoveragePremiumFinal: Float
    rentCoveragePremiumDiscretionaryAdjustment: Float
    depositCoveragePremiumBase: Float
    depositCoveragePenalSum: Float
    depositCoveragePremiumFinal: Float
    depositCoveragePremiumDiscretionaryAdjustment: Float
    finalPremium: Float
    flatPremium: Float
    policyPrice: Float
    taxesAndRegulatoryFees: TaxesAndRegulatoryFees
    discretionaryAdjustmentTotal: Float
    penalSum: Float
    transactionFees: TransactionFees
    total: Float
    upfrontPaymentDiscountPct: Float
  }

  type QuoteOutput {
    monthlyPayment: QuotePaymentDetails
    upfrontPayment: QuotePaymentDetails
  }

  type DiscretionaryAdjustment {
    configuredPct: Float
    configuredDiscretionaryAdjustmentLimit: Float
    type: String
  }

  type Quote {
    id: Int
    meta: QuoteMeta
    collateralDiscountFactor: Float
    collateralDiscountPct: Float
    depositCoverageAmount: Float
    depositCoverageFactor: Float
    depositCoverageMonths: Float
    discretionaryAdjustment: DiscretionaryAdjustment
    freeRent: Float
    isCoverFreeRentEnabled: Boolean
    isCoverPrepaidRentEnabled: Boolean
    isProratedDaysEnabled: Boolean
    leaseStartDate: String
    leaseEndDate: String
    leaseMonths: Float
    monthlyRent: Float
    prepaidRent: Float
    premiumRate: Float
    rateFilingData: String
    rateFilingVersion: String
    rateTier: String
    renewalDiscountPct: Float
    rentCoverageAmount: Float
    rentCoverageFactor: Float
    rentCoverageMonths: Float
    screeningStatus: String
    output: QuoteOutput
    location: String
    esEnabled: Boolean
  }

  type ApplicationLeaseSnapshot {
    id: Int
    monthlyRent: Int
    leaseStartDate: String
    buildingAndUnit: String
  }

  type DefaultCalculatedCoverages {
    lgCoverage: Float
    sdrCoverage: Float
  }

  type ApplicationSettings {
    comment: String
    firstMonthlyPaymentDate: String
    isMonthlyPayment: Boolean
    isVouchedPremium: Boolean
  }

  type ApplicationRenterProvidedData {
    leaseMonths: Int
    monthlyRent: Float
    apartmentNumber: String
  }
`;

export default [
  ApplicationsTypeDef,
  ...DealTypeDef,
  ...PropertyTypeDef,
  ...ContactTypeDef,
  ...InvitationTypeDef,
  ...ApplicationPaymentTypeDef,
  ...CancelDealTypeDef,
  ...EvaluateApplicationTypeDef,
  ...ProcessEvaluatedApplicationTypeDef,
];
