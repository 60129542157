"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirmationStatuses = exports.EXTERNAL_APP_ERROR_REDIRECT = exports.DISCRETIONARY_TYPES = exports.RATE_FILING_VERSION = exports.RESIDENCY_STATUS = exports.RISK_CATEGORY = exports.USER_CATEGORY = exports.EMPLOYMENT_STATUS = exports.RESIDENCY = exports.APPLICATION_STATUSES = void 0;
var APPLICATION_STATUSES;
(function (APPLICATION_STATUSES) {
    APPLICATION_STATUSES["QUOTE_READY"] = "quote-ready";
    APPLICATION_STATUSES["DOCUMENTS_SIGNED"] = "documents-signed";
    APPLICATION_STATUSES["CREATED"] = "created";
    APPLICATION_STATUSES["INVITATION_SENT"] = "invitation-sent";
    APPLICATION_STATUSES["INVITATION_OPENED"] = "invitation-opened";
    APPLICATION_STATUSES["ABORTED"] = "aborted";
    APPLICATION_STATUSES["PROCESSING_PAYMENT"] = "processing-payment";
    APPLICATION_STATUSES["PAID"] = "paid";
    APPLICATION_STATUSES["COMPLETED"] = "completed";
    APPLICATION_STATUSES["CANCELED"] = "canceled";
    APPLICATION_STATUSES["AMENDED"] = "amended";
    APPLICATION_STATUSES["QUESTIONNAIRE_STARTED"] = "questionnaire-started";
    APPLICATION_STATUSES["QUESTIONNAIRE_COMPLETED"] = "questionnaire-completed";
    APPLICATION_STATUSES["PENDING_LEASE_DETAILS"] = "pending-lease-details";
    APPLICATION_STATUSES["APPROVED"] = "approved";
    APPLICATION_STATUSES["QUALIFIED"] = "qualified";
    APPLICATION_STATUSES["CLOSED_LOST"] = "closed-lost";
})(APPLICATION_STATUSES = exports.APPLICATION_STATUSES || (exports.APPLICATION_STATUSES = {}));
var RESIDENCY;
(function (RESIDENCY) {
    RESIDENCY["US"] = "US";
    RESIDENCY["INTERNATIONAL"] = "Intl";
})(RESIDENCY = exports.RESIDENCY || (exports.RESIDENCY = {}));
var EMPLOYMENT_STATUS;
(function (EMPLOYMENT_STATUS) {
    EMPLOYMENT_STATUS["EMPLOYED"] = "employed";
    EMPLOYMENT_STATUS["SELF_EMPLOYED"] = "self-employed";
    EMPLOYMENT_STATUS["FULL_TIME_STUDENT"] = "full-time-student";
    EMPLOYMENT_STATUS["RETIRED"] = "retired";
    EMPLOYMENT_STATUS["UNEMPLOYED"] = "unemployed";
    EMPLOYMENT_STATUS["CORPORATION"] = "corporation";
    EMPLOYMENT_STATUS["OTHER"] = "other";
})(EMPLOYMENT_STATUS = exports.EMPLOYMENT_STATUS || (exports.EMPLOYMENT_STATUS = {}));
var USER_CATEGORY;
(function (USER_CATEGORY) {
    USER_CATEGORY["GENERAL"] = "General";
    USER_CATEGORY["SELF_EMPLOYED"] = "SelfEmployed";
    USER_CATEGORY["NOT_IN_WORKFORCE"] = "NotInWorkforce";
    USER_CATEGORY["CORPORATE_LEASE"] = "CorporateLease";
    USER_CATEGORY["RELOCATE"] = "Relocate";
    USER_CATEGORY["FIRST_TIME"] = "FirstTime";
    USER_CATEGORY["STUDENT"] = "Student";
    USER_CATEGORY["CORPORATE"] = "Corporate";
    USER_CATEGORY["NON_US_STUDENTS"] = "NonUsStudents";
    USER_CATEGORY["NON_US_RESIDENTS"] = "NonUsResidents";
    USER_CATEGORY["ALL_OTHER"] = "AllOther";
})(USER_CATEGORY = exports.USER_CATEGORY || (exports.USER_CATEGORY = {}));
var RISK_CATEGORY;
(function (RISK_CATEGORY) {
    RISK_CATEGORY["EXTRA_LOW"] = "ExtraLow";
    RISK_CATEGORY["LOW"] = "Low";
    RISK_CATEGORY["MEDIUM"] = "Medium";
    RISK_CATEGORY["HIGH"] = "High";
    RISK_CATEGORY["CORPORATE"] = "Corporate";
    RISK_CATEGORY["EXTRA_LOW_STUDENT"] = "ExtraLow-Student";
    RISK_CATEGORY["EXTRA_LOW_NONSTUDENT"] = "ExtraLow-NonStudent";
    RISK_CATEGORY["LOW_STUDENT"] = "Low-Student";
    RISK_CATEGORY["LOW_NONSTUDENT"] = "Low-NonStudent";
    RISK_CATEGORY["MEDIUM_STUDENT"] = "Medium-Student";
    RISK_CATEGORY["MEDIUM_NONSTUDENT"] = "Medium-NonStudent";
    RISK_CATEGORY["HIGH_STUDENT"] = "High-Student";
    RISK_CATEGORY["HIGH_NONSTUDENT"] = "High-NonStudent";
})(RISK_CATEGORY = exports.RISK_CATEGORY || (exports.RISK_CATEGORY = {}));
var RESIDENCY_STATUS;
(function (RESIDENCY_STATUS) {
    RESIDENCY_STATUS["US_CITIZEN"] = "US";
    RESIDENCY_STATUS["GREEN_CARD_HOLDER"] = "green_card";
    RESIDENCY_STATUS["NEITHER"] = "neither";
})(RESIDENCY_STATUS = exports.RESIDENCY_STATUS || (exports.RESIDENCY_STATUS = {}));
var RATE_FILING_VERSION;
(function (RATE_FILING_VERSION) {
    RATE_FILING_VERSION["V1"] = "v1";
    RATE_FILING_VERSION["V2"] = "v2";
    RATE_FILING_VERSION["V3"] = "v3";
})(RATE_FILING_VERSION = exports.RATE_FILING_VERSION || (exports.RATE_FILING_VERSION = {}));
var DISCRETIONARY_TYPES;
(function (DISCRETIONARY_TYPES) {
    DISCRETIONARY_TYPES["SURCHARGE"] = "surcharge";
    DISCRETIONARY_TYPES["DISCOUNT"] = "discount";
    DISCRETIONARY_TYPES["NONE"] = "none";
})(DISCRETIONARY_TYPES = exports.DISCRETIONARY_TYPES || (exports.DISCRETIONARY_TYPES = {}));
var EXTERNAL_APP_ERROR_REDIRECT;
(function (EXTERNAL_APP_ERROR_REDIRECT) {
    EXTERNAL_APP_ERROR_REDIRECT["ERROR"] = "error";
    EXTERNAL_APP_ERROR_REDIRECT["DENIED"] = "denied";
})(EXTERNAL_APP_ERROR_REDIRECT = exports.EXTERNAL_APP_ERROR_REDIRECT || (exports.EXTERNAL_APP_ERROR_REDIRECT = {}));
exports.confirmationStatuses = [
    APPLICATION_STATUSES.COMPLETED,
    APPLICATION_STATUSES.PROCESSING_PAYMENT,
];
