import type { ReportEntity } from '../../../../api/api-types';
import type { Except } from 'type-fest';
import type { ApplicationDetailsResponseApplication } from '../../details.types';

export interface DecisionSummaryInfoProps {
  application: ApplicationDetailsResponseApplication | undefined;
}

export enum STEP_AUDIT_STATUS_RAW {
  PASSED = 'passed',
  FAILED = 'failed',
  ERROR = 'error',
  NOT_EXECUTED = 'not executed',
}

export enum STEP_AUDIT_STATUS {
  PASS = 'Pass',
  FAIL = 'Fail',
  ERROR = 'Error',
  NOT_EXECUTED = 'Not Executed',
}

export interface WorkflowAuditEntity {
  id: number;
  name?: string;
  status: string;
  evaluationType: string;
  stepAudit: StepAudit[];
  payload: string;
  record: ReportEntity;
  createdAt: string;
  updatedAt: string;
}
export type WorkflowAuditWithParsedPayload = Except<WorkflowAuditEntity, 'payload'> & {
  payload: WorkflowAuditPayload;
};

export interface WorkflowAuditPayload {
  responses: {
    equifax: Record<string, unknown>;
    sentilink: Record<string, unknown>;
    riskModel: Record<string, unknown>;
  };
  inputs: {
    equifax: Record<string, unknown>;
    sentilink: Record<string, unknown>;
    riskModel: Record<string, unknown>;
    initialInput: Record<string, unknown>;
  };
}

export interface StepAudit {
  step: number;
  name: string;
  id: string;
  type: string;
  status: string;
  description: string;
  message: string;
  status_code: string;
}

export interface WorkflowEntity {
  id: number;
  name: string;
  providerName: string;
  schema: WorkflowSchema;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export type WorkflowSchema = WorkflowStep[];

export interface WorkflowStep {
  order: number;
  name: STEP_NAMES;
  displayName: string;
  id: string;
  type: string;
  description: string;
  rules: StepRule[] | StepRule[][];
  jumpToStepRulesMatch: number | null;
  jumpToStepRulesNoMatch: number | null;
  payloadFieldName?: string;
  payloadFieldValue?: string;
  shouldExecuteActionOnRulesFail?: boolean;
}

export enum STEP_NAMES {
  EQUIFAX = 'Equifax',
  SENTILINK = 'Sentilink',
  RISK_MODEL = 'RiskModel',
  SET_PAYLOAD_VALUE = 'SetPayloadValue',
}

export interface StepRule {
  field: string;
  operator: string;
  value?: string | number | boolean | string[] | number[];
}
