import React, { FC, useMemo } from 'react';
import { useGetFraudInquiry } from '../../hooks/use-get-fraud-inquiry';
import { InfoContainer } from '@theguarantors/ui-kit-v3';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';

interface KountInfoProps {
  readonly applicationId: number | undefined;
}

export const KountInfo: FC<KountInfoProps> = ({ applicationId }) => {
  const [, { isEnabled: isKountEnabled }] = useFeatureFlag(FeatureFlag.REN_5655_IS_KOUNT_ENABLED);
  const { data: fraudInquiry, called, loading } = useGetFraudInquiry(applicationId, isKountEnabled);

  const renderChild = useMemo(() => {
    if (fraudInquiry) {
      return null;
    }

    if (loading) {
      return <div>Loading...</div>;
    }

    if (called) {
      return <div>No Kount data found</div>;
    }
  }, [called, fraudInquiry, loading]);

  if (!isKountEnabled) {
    return null;
  }

  return (
    <InfoContainer
      extra={{
        icon: 'lock',
        tooltipTitle: 'This information can`t be edited',
        tooltipId: 'dLargeButton',
      }}
      title="Kount Decision"
      color="neutral.main"
      data={
        fraudInquiry
          ? [
              { label: 'Status', value: fraudInquiry?.status },
              { label: 'Score', value: fraudInquiry?.score },
              { label: 'External Provider ID', value: fraudInquiry?.externalProviderId },
              { label: 'Processed At', value: fraudInquiry?.processedAt },
            ]
          : []
      }
    >
      {renderChild}
    </InfoContainer>
  );
};
