"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCoverageMonths = void 0;
const dayjs = require("dayjs");
function getDatesParts(data, logger) {
    logger.info(`[getCoverageMonths] StartDate: ${data.leaseStartDate}, EndDate: ${data.leaseEndDate}`);
    const daysStart = dayjs(data.leaseStartDate);
    const daysEnd = dayjs(data.leaseEndDate);
    const isLeapStartYear = new Date(+daysStart.get('year'), 1, 29).getDate() === 29;
    const isLeapEndYear = new Date(+daysEnd.get('year'), 1, 29).getDate() === 29;
    logger.info(`[getCoverageMonths] isLeapStartYear: ${isLeapStartYear}, isLeapEndYear: ${isLeapEndYear}`);
    const startYearDaysInMonths = [
        31,
        isLeapStartYear ? 29 : 28,
        31,
        30,
        31,
        30,
        31,
        31,
        30,
        31,
        30,
        31,
    ];
    const endYearDaysInMonths = [31, isLeapEndYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const daysInStartMonth = startYearDaysInMonths[+daysStart.get('month')];
    const daysInEndMonth = endYearDaysInMonths[+daysEnd.get('month')];
    logger.info(`[getCoverageMonths] DaysInStartMonth: ${daysInStartMonth}, DaysInEndMonth: ${daysInEndMonth}`);
    const dayOfStart = +daysStart.get('date');
    const dayOfEnd = +daysEnd.get('date');
    logger.info(`[getCoverageMonths] DayOfStart: ${dayOfStart}, DayOfEnd: ${dayOfEnd}`);
    const monthsDiff = +daysEnd.diff(daysStart, 'months', false);
    logger.info(`[getCoverageMonths] Difference in months: ${monthsDiff}`);
    const startFraction = (daysInStartMonth - dayOfStart + 1) / daysInStartMonth;
    const endFraction = Math.min(daysInEndMonth, dayOfEnd) / daysInEndMonth;
    logger.info(`[getCoverageMonths] Fractions: Start: ${startFraction}, End: ${endFraction}`);
    return {
        dayOfStart,
        dayOfEnd,
        daysInStartMonth,
        daysInEndMonth,
        monthsDiff,
        startFraction,
        endFraction,
    };
}
const getMonthDiffWithFraction = (logger, data, dateParts) => () => {
    let val = 0;
    const { dayOfStart, dayOfEnd, daysInEndMonth, monthsDiff, startFraction, endFraction } = dateParts;
    if (dayOfStart === dayOfEnd + 1 || (dayOfStart === 1 && dayOfEnd === daysInEndMonth)) {
        val = monthsDiff + 1;
    }
    else {
        let totalFraction = startFraction + endFraction;
        const notFullMonthsCase = dayOfStart === dayOfEnd && totalFraction < 1;
        if (totalFraction > 1 || notFullMonthsCase) {
            totalFraction -= 1;
        }
        if (notFullMonthsCase && !data.isProratedDaysCovered) {
            totalFraction = Math.round(totalFraction);
        }
        val = monthsDiff + totalFraction;
    }
    logger.info(`[getCoverageMonths] Total months computed: ${val}`);
    return val;
};
const applyFreeRentDiscount = (logger, data) => (val) => {
    const newVal = !data.isFreeRentCovered && data.freeRent ? val - data.freeRent / Number(data.monthlyRent) : val;
    logger.info(`[getCoverageMonths] After free rent, total months: ${newVal}`);
    return newVal;
};
const applyPrepaidRentDiscount = (logger, data) => (val) => {
    const newVal = !data.isPrepaidRentCovered && data.prepaidRent
        ? val - data.prepaidRent / Number(data.monthlyRent)
        : val;
    logger.info(`[getCoverageMonths] After prepaid rent, total months: ${newVal}`);
    return newVal;
};
const applyRoundTweak = (logger) => (val) => {
    var _a;
    const totalMonthDecimal = (_a = val.toString().split('.')[1]) === null || _a === void 0 ? void 0 : _a.substring(0, 4);
    let newVal = 0;
    if (totalMonthDecimal && +totalMonthDecimal === 9999) {
        newVal = val + 0.0001;
    }
    else {
        newVal = val;
    }
    logger.info(`[getCoverageMonths] After rounding tweak for edge case, total months: ${newVal}`);
    return newVal;
};
const applyPropagation = (logger, data) => (val) => {
    const newVal = !data.isProratedDaysCovered ? Math.floor(val) : val;
    logger.info(`[getCoverageMonths] Prorated Disabled, total months: ${newVal}`);
    return newVal;
};
const getFinalResult = (logger) => (val) => {
    const newVal = Math.round((val + Number.EPSILON) * 10000) / 10000;
    logger.info(`[getCoverageMonths] Final result, total months: ${newVal}`);
    return newVal;
};
function getCoverageMonths(data, logger) {
    if (!data.leaseStartDate && !data.leaseEndDate) {
        logger.info('[getCoverageMonths] Skip calculations and use rentCoverageMonths value');
        return data.rentCoverageMonths;
    }
    const datesParts = getDatesParts(data, logger);
    return [
        getMonthDiffWithFraction(logger, data, datesParts),
        applyFreeRentDiscount(logger, data),
        applyPrepaidRentDiscount(logger, data),
        applyRoundTweak(logger),
        applyPropagation(logger, data),
        getFinalResult(logger),
    ].reduce((acc, fn) => fn(acc), 0);
}
exports.getCoverageMonths = getCoverageMonths;
